import React, { useEffect, useState } from "react";
import { Default } from "../../components/default/Default";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { setDocumentationList } from "../../reducer/documentation/documentation.action";
import "./documentation.css";
import { AiOutlineFileText, AiOutlineReload } from "react-icons/ai";
import { Card, Col, Row } from "react-bootstrap";

const DocumentationList = () => {
  const dispatch = useDispatch();
  const _documentationList = useSelector(
    (state) => state.documentationReducer.documentationList
  );

  const _loaderDocumentationList = useSelector(
    (state) => state.documentationReducer.loaderDocumentationList
  );

  const getDocumentationList = async () => {
    try {
      await dispatch(setDocumentationList());
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    getDocumentationList();
  }, []);

  if (_loaderDocumentationList) {
    return (
      <Default>
        <div className="text-center">
          <AiOutlineReload size={50} className="loader-animation" />
        </div>
      </Default>
    );
  }

  if (!_documentationList || _documentationList.length === 0) {
    return (
      <Default>
        <p>No documentation available.</p>
      </Default>
    );
  }

  return (
    <Default>
      <Row className="pt-3">
        {_documentationList.map((doc, index) => (
          <Col md={4} key={index} className="mt-3">
            <Card style={{ width: "25rem" }} className="custom-card">
              <div className="text-center p-4">
                <AiOutlineFileText size={60} className="icon" color="#007bff" />
              </div>
              <Card.Body className="mt-2">
                <NavLink to={`/documentation/${doc?.id}`} className="nav-link">
                  <Card.Text className="custom-text">{doc?.title}</Card.Text>
                </NavLink>
                <Card.Text
                  className="custom-content pt-3"
                  dangerouslySetInnerHTML={{
                    __html: doc?.short_description,
                  }}
                ></Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Default>
  );
};

export default DocumentationList;
