import React, { useEffect, useState } from "react";
import { Default } from "../../components/default/Default";
import CollectionForm from "../../components/collections/CollectionForm";
import { useParams } from "react-router-dom";
import PostRequest from "../../services/PostRequest";

const EditCollection = () => {
  const params = useParams();

  const [activeCollection, setActiveCollection] = useState({});

  const getSingleCollection = async () => {
    try {
      const response = await PostRequest("/schedules/get/", {
        schedule_id: params.id,
      });
      setActiveCollection(response);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getSingleCollection();
  }, []);

  return (
    <>
      <Default>
        <CollectionForm activeCollection={activeCollection} />
      </Default>
    </>
  );
};

export default EditCollection;
