import { AiOutlineCopy, AiOutlineDelete } from "react-icons/ai";
import { handleCopy, showToaster } from "../../common/common";
import { Badge, Dropdown, Form, Pagination, Table } from "react-bootstrap";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Fragment, useEffect, useState } from "react";
import PostRequest from "../../services/PostRequest";
import { useDispatch, useSelector } from "react-redux";
import { setZipCodeList } from "../../reducer/zipCode/ZipCode.action";
import { ZipCodeLoader } from "../../loader/Loader";
import { CountryOption } from "../../common/custom-options";

export const PostCodesTable = () => {
  const dispatch = useDispatch();
  const zipCodesList = useSelector((item) => item.zipCodeReducer.zipCodesList);
  const [checkedItems, setCheckedItems] = useState({});
  const [loader, setLoader] = useState(false);

  const handelDeleteZipCode = async (id) => {
    try {
      if (!window.confirm("Are you sure you want to delete zipcode?"))
        return false;

      const data = {
        zipcode_id: id,
      };
      await PostRequest("/zipcodes/delete-zipcode/", data);
      showToaster("Zipcode deleted successfully.");
      dispatch(setZipCodeList());
    } catch (error) {
      console.log("error", error.message);
      showToaster(error.message, "error");
    }
  };

  const columns = [
    {
      accessorKey: "domain",
      header: "Domain",
      cell: ({ row, ...info }) => {
        return (
          <>
            <CountryOption
              data={{
                countryCode: row.original.country_code,
                label: info.getValue(),
              }}
            />
          </>
        );
      },
    },
    {
      accessorKey: "zipcode",
      header: "Zip/Postal Code",
      cell: (info) => {
        return (
          <>
            <span className="me-1">{info.getValue()}</span>
            <AiOutlineCopy
              onClick={() => handleCopy(info.getValue())}
              className="cursor-pointer"
            />
          </>
        );
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: (info) => (
        <Badge bg={info.getValue() === "active" ? "success" : "danger"}>
          {info.getValue()}
        </Badge>
      ),
    },
    {
      id: "id",
      cell: ({ row }) => (
        <Dropdown>
          <Dropdown.Toggle
            as="a"
            className="cursor-pointer text-decoration-none"
            variant="success"
            id="dropdown-basic"
          >
            Actions
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => handelDeleteZipCode(row.original.id)}
              as="button"
            >
              <AiOutlineDelete size={19} className="mb-1" /> Delete Zipcode
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ];

  const tableInstance = useReactTable({
    columns,
    data: zipCodesList,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleHeaderCheckboxChange = (e) => {
    const checked = e.target.checked;
    const newCheckedItems = {};
    zipCodesList.forEach((_, index) => {
      newCheckedItems[index] = checked;
    });
    setCheckedItems(newCheckedItems);
  };

  const handleRowCheckboxChange = (id) => {
    setCheckedItems((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const loadZipCodes = async () => {
    try {
      setLoader(true);

      await dispatch(setZipCodeList());
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    loadZipCodes();
  }, []);

  return (
    <>
      <Table responsive>
        <thead className="sticky-top">
          {tableInstance.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              <th>
                <Form.Check
                  type="checkbox"
                  checked={
                    Object.keys(checkedItems).length === zipCodesList.length &&
                    Object.values(checkedItems).every(Boolean)
                  }
                  onChange={handleHeaderCheckboxChange}
                />
              </th>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {loader ? (
            <ZipCodeLoader />
          ) : (
            <Fragment>
              {tableInstance.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  <td>
                    <Form.Check
                      type="checkbox"
                      checked={checkedItems[row.id] || false}
                      onChange={() => handleRowCheckboxChange(row.id)}
                    />
                  </td>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </Fragment>
          )}

          {loader ? (
            <ZipCodeLoader />
          ) : (
            <Fragment>
              {zipCodesList.length == "0" && (
                <tr>
                  <td
                    colSpan={tableInstance.getAllColumns().length}
                    style={{ border: "none" }}
                    className="text-center"
                  >
                    <h6 className="mt-4">No any postcode.</h6>
                  </td>
                </tr>
              )}
            </Fragment>
          )}

          {/* <tr>
            <td colSpan={6} className="text-center">
              <span>No zipcodes set up</span>
            </td>
          </tr> */}
        </tbody>
      </Table>
      {/* <Pagination className="justify-content-end mb-0">
        <Pagination.Prev />
        <Pagination.Item active>{1}</Pagination.Item>
        <Pagination.Ellipsis />

        <Pagination.Item>{11}</Pagination.Item>
        <Pagination.Item active>{12}</Pagination.Item>
        <Pagination.Item>{13}</Pagination.Item>

        <Pagination.Ellipsis />
        <Pagination.Item>{20}</Pagination.Item>
        <Pagination.Next />
      </Pagination> */}
    </>
  );
};
