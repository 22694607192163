import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { IoHelpCircleOutline } from "react-icons/io5";
import Select from "react-select";

import {
  amazonDomainOptions,
  includeOptions,
  outputOptions,
} from "../data/constants";
import { ChooseAPI, CountryOption } from "../common/custom-options";
import { useSelector } from "react-redux";
import { GenerateKey } from "../common/common";

export const APIRequestFields = ({
  isCustomID = false,
  _asinDetails = {},
  _zipCodesList = [],
}) => {
  return (
    <Row className="gy-3 mb-3">
      <Col sm={6}>
        <Form.Group>
          <Form.Label>
            <span>Amazon Domain</span>
            <OverlayTrigger overlay={<Tooltip>Amazon Domain</Tooltip>}>
              <span>
                <IoHelpCircleOutline size={20} className="cursor-pointer" />
              </span>
            </OverlayTrigger>
          </Form.Label>
          <Select
            key={GenerateKey(10)}
            options={amazonDomainOptions}
            defaultValue={
              amazonDomainOptions.find(
                (option) => option.value === _asinDetails?.amazon_domain
              ) || amazonDomainOptions[1] // default to second option if not found
            }
            name="amazon_domain"
            isClearable
            getOptionLabel={(option) => <CountryOption data={option} />}
            menuPlacement="auto"
          />
        </Form.Group>
      </Col>

      <Col sm={6}>
        <Form.Group>
          <Form.Label>
            <span>ASIN</span>
            <OverlayTrigger overlay={<Tooltip>ASIN</Tooltip>}>
              <span>
                <IoHelpCircleOutline size={20} className="cursor-pointer" />
              </span>
            </OverlayTrigger>
          </Form.Label>
          <Form.Control
            key={GenerateKey(10)}
            type="text"
            defaultValue={_asinDetails ? _asinDetails?.asin : ""}
            name="asin"
          />
        </Form.Group>
      </Col>

      <Col sm={12}>
        <Form.Group>
          <Form.Label>
            <span>Customer Postcode/Zipcode</span>
            <OverlayTrigger
              overlay={<Tooltip>Customer Postcode/Zipcode</Tooltip>}
            >
              <span>
                <IoHelpCircleOutline size={20} className="cursor-pointer" />
              </span>
            </OverlayTrigger>
          </Form.Label>
          <Select
            key={GenerateKey(10)}
            menuPlacement="auto"
            isClearable
            isSearchable
            defaultValue={
              _asinDetails
                ? {
                    value: _asinDetails.customer_postcode,
                    label: _asinDetails.customer_postcode,
                  }
                : null
            }
            options={_zipCodesList.map((item) => ({
              value: item.zipcode,
              label: item.zipcode,
            }))}
            name="customer_postcode"
            noOptionsMessage={() =>
              "No customer zipcodes have been set up for amazon.com.au"
            }
          />
        </Form.Group>
      </Col>

      <Col sm={12}>Output</Col>
      <Col sm={6}>
        <Form.Group>
          <Form.Label>
            <span>Output</span>
            <OverlayTrigger overlay={<Tooltip>Output</Tooltip>}>
              <span>
                <IoHelpCircleOutline size={20} className="cursor-pointer" />
              </span>
            </OverlayTrigger>
          </Form.Label>
          <Select
            key={GenerateKey(10)}
            options={outputOptions}
            menuPlacement="auto"
            defaultValue={
              outputOptions.find(
                (option) => option.value === _asinDetails?.output
              ) || null
            }
            name="output"
            getOptionLabel={(option) => <ChooseAPI data={option} />}
          />
        </Form.Group>
      </Col>
      <Col sm={6}>
        <Form.Group>
          <Form.Label>
            <span>Include Raw HTML</span>
            <OverlayTrigger overlay={<Tooltip>Include Raw HTML</Tooltip>}>
              <span>
                <IoHelpCircleOutline size={20} className="cursor-pointer" />
              </span>
            </OverlayTrigger>
          </Form.Label>
          <Select
            key={GenerateKey(10)}
            options={includeOptions}
            defaultValue={
              includeOptions.find(
                (option) => option.value === _asinDetails?.include_raw_html
              ) || null
            }
            menuPlacement="auto"
            name="include_raw_html"
            // include_row_html
          />
        </Form.Group>
      </Col>

      {isCustomID && (
        <Col sm={12}>
          <Form.Group>
            <Form.Label>
              <span>Custom ID</span>
              <OverlayTrigger overlay={<Tooltip>Custom ID</Tooltip>}>
                <span>
                  <IoHelpCircleOutline size={20} className="cursor-pointer" />
                </span>
              </OverlayTrigger>
            </Form.Label>
            <Form.Control
              key={GenerateKey(10)}
              defaultValue={_asinDetails ? _asinDetails?.custom_id : ""}
              type="text"
              name="custom_id"
            />
          </Form.Group>
        </Col>
      )}
    </Row>
  );
};
