import ReactCountryFlag from "react-country-flag";

export const ChooseAPI = ({ data }) => {
  const { label, icon: Icon, endpoint } = data;
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {Icon && <Icon size={20} style={{ marginRight: 10 }} />}
      <p className="mb-0 text-truncate">
        {label}{" "}
        {endpoint && (
          <span className="font-14" style={{ color: "lightgrey" }}>
            {endpoint}
          </span>
        )}
      </p>
    </div>
  );
};

export const ImageLabel = ({ data, ...rest }) => {
  const { label, image } = data;
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        src={image}
        alt={label}
        style={{ width: 20, height: 20, marginRight: 10 }}
        {...rest}
      />
      <span>{label}</span>
    </div>
  );
};

export const CountryOption = ({ data }) => {
  const { label, value, countryCode } = data;
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <ReactCountryFlag
        countryCode={countryCode || value}
        svg
        style={{
          width: "2em",
          height: "2em",
        }}
      />
      <span className="text-truncate">{label}</span>
    </div>
  );
};
