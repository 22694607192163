import { Badge, Button, Form, Offcanvas } from "react-bootstrap";
import Select from "react-select";
import {
  // allowedRequestType,
  apiOptions,
  daysOfMonth,
  daysOfWeek,
  hoursOptions,
  minutesFrequencyOptions,
} from "../../data/constants";
import { ChooseAPI } from "../../common/custom-options";
import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ChooseRequests } from "./ChooseRequests";
import { showToaster } from "../../common/common";
import PostRequest from "../../services/PostRequest";
import { useDispatch, useSelector } from "react-redux";
import { setCollectionsList } from "../../reducer/collection/Collection.action";
import { asinView } from "../../reducer/default/default.action";
import CollectionForm from "./CollectionForm";

export const AddCollection = ({ show, handleClose, activeCollection }) => {

  const [selectedSchedule, setSelectedSchedule] = useState(null);
  console.log(selectedSchedule);
  const [showRequests, setShowRequests] = useState(false);
  const [collectionId, setCollectionId] = useState(false);

  const [activeCollectionData, setActiveCollectionData] = useState({});

  const handleChange = (event) => setSelectedSchedule(event.target.value);

  useEffect(() => {
    setActiveCollectionData(activeCollection);
    console.log("data.......");
  }, [activeCollection]);

  return (
    <Fragment>
      <Offcanvas
        placement="end"
        style={{ width: "550px" }}
        show={show}
        onHide={() => {
          handleClose();
          setSelectedSchedule(null);
        }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add New Collection</Offcanvas.Title>
        </Offcanvas.Header>

        <CollectionForm
          handleClose={handleClose}
          // selectedSchedule={selectedSchedule}
          // setSelectedSchedule={setSelectedSchedule}
          setShowRequests={setShowRequests}
          setCollectionId={setCollectionId}
        // handleChange={handleChange}
        />
      </Offcanvas>

      <ChooseRequests
        show={showRequests}
        handleClose={() => setShowRequests(false)}
        setShowRequests={setShowRequests}
        collectionId={collectionId?.id}
      />
    </Fragment>
  );
};
