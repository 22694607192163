import { BsFiletypeJson } from "react-icons/bs";
import {
  AiOutlineCodeSandbox,
  AiOutlineStock,
  AiOutlineFund,
  AiOutlineSearch,
  AiOutlineUnorderedList,
  AiOutlineDollar,
  AiOutlineMessage,
  AiOutlineSmile,
} from "react-icons/ai";
import { IoCardOutline, IoSearch } from "react-icons/io5";

export const timeZoneOptions = [
  {
    value: "(GMT -12:00) Eniwetok, Kwajalein",
    label: "(GMT -12:00) Eniwetok, Kwajalein",
  },
  {
    value: "(GMT -11:00) Midway Island, Samoa",
    label: "(GMT -11:00) Midway Island, Samoa",
  },
  { value: "(GMT -10:00) Hawaii", label: "(GMT -10:00) Hawaii" },
  { value: "(GMT -9:30) Taiohae", label: "(GMT -9:30) Taiohae" },
  { value: "(GMT -9:00) Alaska", label: "(GMT -9:00) Alaska" },
  {
    value: "(GMT -8:00) Pacific Time (US & Canada)",
    label: "(GMT -8:00) Pacific Time (US & Canada)",
  },
  {
    value: "(GMT -7:00) Mountain Time (US & Canada)",
    label: "(GMT -7:00) Mountain Time (US & Canada)",
  },
  {
    value: "(GMT -6:00) Central Time (US & Canada), Mexico City",
    label: "(GMT -6:00) Central Time (US & Canada), Mexico City",
  },
  {
    value: "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima",
    label: "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima",
  },
  { value: "(GMT -4:30) Caracas", label: "(GMT -4:30) Caracas" },
  {
    value: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz",
    label: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz",
  },
  { value: "(GMT -3:30) Newfoundland", label: "(GMT -3:30) Newfoundland" },
  {
    value: "(GMT -3:00) Brazil, Buenos Aires, Georgetown",
    label: "(GMT -3:00) Brazil, Buenos Aires, Georgetown",
  },
  { value: "(GMT -2:00) Mid-Atlantic", label: "(GMT -2:00) Mid-Atlantic" },
  {
    value: "(GMT -1:00) Azores, Cape Verde Islands",
    label: "(GMT -1:00) Azores, Cape Verde Islands",
  },
  {
    value: "(GMT) Western Europe Time, London, Lisbon, Casablanca",
    label: "(GMT) Western Europe Time, London, Lisbon, Casablanca",
  },
  {
    value: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris",
    label: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris",
  },
  {
    value: "(GMT +2:00) Kaliningrad, South Africa",
    label: "(GMT +2:00) Kaliningrad, South Africa",
  },
  {
    value: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg",
    label: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg",
  },
  { value: "(GMT +3:30) Tehran", label: "(GMT +3:30) Tehran" },
  {
    value: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi",
    label: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi",
  },
  { value: "(GMT +4:30) Kabul", label: "(GMT +4:30) Kabul" },
  {
    value: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent",
    label: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent",
  },
  {
    value: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi",
    label: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi",
  },
  {
    value: "(GMT +5:45) Kathmandu, Pokhara",
    label: "(GMT +5:45) Kathmandu, Pokhara",
  },
  {
    value: "(GMT +6:00) Almaty, Dhaka, Colombo",
    label: "(GMT +6:00) Almaty, Dhaka, Colombo",
  },
  {
    value: "(GMT +6:30) Yangon, Mandalay",
    label: "(GMT +6:30) Yangon, Mandalay",
  },
  {
    value: "(GMT +7:00) Bangkok, Hanoi, Jakarta",
    label: "(GMT +7:00) Bangkok, Hanoi, Jakarta",
  },
  {
    value: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong",
    label: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong",
  },
  { value: "(GMT +8:45) Eucla", label: "(GMT +8:45) Eucla" },
  {
    value: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk",
    label: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk",
  },
  {
    value: "(GMT +9:30) Adelaide, Darwin",
    label: "(GMT +9:30) Adelaide, Darwin",
  },
  {
    value: "(GMT +10:00) Eastern Australia, Guam, Vladivostok",
    label: "(GMT +10:00) Eastern Australia, Guam, Vladivostok",
  },
  {
    value: "(GMT +10:30) Lord Howe Island",
    label: "(GMT +10:30) Lord Howe Island",
  },
  {
    value: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia",
    label: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia",
  },
  {
    value: "(GMT +11:30) Norfolk Island",
    label: "(GMT +11:30) Norfolk Island",
  },
  {
    value: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka",
    label: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka",
  },
  {
    value: "(GMT +12:45) Chatham Islands",
    label: "(GMT +12:45) Chatham Islands",
  },
  {
    value: "(GMT +13:00) Apia, Nukualofa",
    label: "(GMT +13:00) Apia, Nukualofa",
  },
  {
    value: "(GMT +14:00) Line Islands, Tokelau",
    label: "(GMT +14:00) Line Islands, Tokelau",
  },
];

export const expireOptions = [
  { value: "1 day", label: "1 day" },
  { value: "2 days", label: "2 days" },
  { value: "3 days", label: "3 days" },
  { value: "4 days", label: "4 days" },
  { value: "5 days", label: "5 days" },
  { value: "6 days", label: "6 days" },
  { value: "1 week", label: "1 week" },
  { value: "2 weeks", label: "2 weeks" },
  { value: "3 weeks", label: "3 weeks" },
  { value: "1 month", label: "1 month" },
  { value: "2 months", label: "2 months" },
];

export const monthOptions = [
  { value: "2024-10", label: "October 2024" },
  { value: "2024-09", label: "September 2024" },
  { value: "2024-08", label: "August 2024" },
  { value: "2024-07", label: "July 2024" },
  { value: "2024-06", label: "June 2024" },
  { value: "2024-05", label: "May 2024" },
  { value: "2024-04", label: "April 2024" },
  { value: "2024-03", label: "March 2024" },
  { value: "2024-02", label: "February 2024" },
  { value: "2024-01", label: "January 2024" },
  { value: "2023-12", label: "December 2023" },
  { value: "2023-11", label: "November 2023" },
  { value: "2023-10", label: "October 2023" },
  { value: "2023-09", label: "September 2023" },
  { value: "2023-08", label: "August 2023" },
  { value: "2023-07", label: "July 2023" },
  { value: "2023-06", label: "June 2023" },
  { value: "2023-05", label: "May 2023" },
  { value: "2023-04", label: "April 2023" },
  { value: "2023-03", label: "March 2023" },
  { value: "2023-02", label: "February 2023" },
  { value: "2023-01", label: "January 2023" },
  { value: "2022-12", label: "December 2022" },
  { value: "2022-11", label: "November 2022" },
  { value: "2022-10", label: "October 2022" },
  { value: "2022-09", label: "September 2022" },
  { value: "2022-08", label: "August 2022" },
  { value: "2022-07", label: "July 2022" },
  { value: "2022-06", label: "June 2022" },
  { value: "2022-05", label: "May 2022" },
  { value: "2022-04", label: "April 2022" },
  { value: "2022-03", label: "March 2022" },
  { value: "2022-02", label: "February 2022" },
  { value: "2022-01", label: "January 2022" },
  { value: "2021-12", label: "December 2021" },
  { value: "2021-11", label: "November 2021" },
];

export const typeOptions = [
  {
    label: "CORE REQUESTS",
    options: [
      { label: "Product", value: "product", icon: AiOutlineCodeSandbox },
    ],
  },
  {
    label: "SALES & STOCK",
    options: [
      { label: "Stock Estimation", value: "stock", icon: AiOutlineStock },
      { label: "Sales Estimation", value: "sales", icon: AiOutlineFund },
    ],
  },
  // {
  //   label: 'CUSTOMER FEEDBACK'
  // },
  // {
  //   label: 'DEALS & BESTSELLERS'
  // },
  // {
  //   label: 'SELLER DATA'
  // },
  // {
  //   label: 'SPECIALIST PAGES'
  // },
  // {
  //   label: 'UTILITY REQUESTS'
  // }
];

export const skipGTINCacheOptions = [
  { label: "True (requires 2 credits)", value: "true" },
  { label: "False (default, use the cache)", value: "false" },
];

export const amazonDomainOptions = [
  { value: "amazon.com", label: "amazon.com", countryCode: "US" },
  { value: "amazon.com.au", label: "amazon.com.au", countryCode: "AU" },
  { value: "amazon.ca", label: "amazon.ca", countryCode: "CA" },
  { value: "amazon.com.mx", label: "amazon.com.mx", countryCode: "MX" },
  { value: "amazon.co.uk", label: "amazon.co.uk", countryCode: "GB" },
  { value: "amazon.de", label: "amazon.de", countryCode: "DE" },
  { value: "amazon.fr", label: "amazon.fr", countryCode: "FR" },
  { value: "amazon.it", label: "amazon.it", countryCode: "IT" },
  { value: "amazon.es", label: "amazon.es", countryCode: "ES" },
  { value: "amazon.nl", label: "amazon.nl", countryCode: "NL" },
  { value: "amazon.se", label: "amazon.se", countryCode: "SE" },
  { value: "amazon.pl", label: "amazon.pl", countryCode: "PL" },
  { value: "amazon.be", label: "amazon.be", countryCode: "BE" },
  { value: "amazon.at", label: "amazon.at", countryCode: "AT" },
  { value: "amazon.ch", label: "amazon.ch", countryCode: "CH" },
  { value: "amazon.co.jp", label: "amazon.co.jp", countryCode: "JP" },
  { value: "amazon.in", label: "amazon.in", countryCode: "IN" },
  { value: "amazon.cn", label: "amazon.cn", countryCode: "CN" },
  { value: "amazon.sg", label: "amazon.sg", countryCode: "SG" },
  { value: "amazon.ae", label: "amazon.ae", countryCode: "AE" },
  { value: "amazon.com.br", label: "amazon.com.br", countryCode: "BR" },
  { value: "amazon.com.tr", label: "amazon.com.tr", countryCode: "TR" },
];

export const includeOptions = [
  { label: "True", value: true },
  { label: "False (default)", value: false },
];

export const outputOptions = [
  { label: "JSON", value: "JSON", icon: BsFiletypeJson },
  // { label: "HTML", value: "html", icon: BsFiletypeHtml },
  // { label: "CSV", value: "csv", icon: BsFiletypeCsv },
];

export const allowedRequestType = [
  { value: "mixed", label: "Mixed", endpoint: "(allow any request type)" },
  {
    value: "product",
    label: "Product",
    endpoint: "(allow only Product requests in this Collection)",
    icon: AiOutlineCodeSandbox,
  },
  {
    value: "search",
    label: "Search",
    endpoint: "(allow only Search requests in this Collection)",
    icon: AiOutlineSearch,
  },
  {
    value: "category",
    label: "Category",
    endpoint: "(allow only Category requests in this Collection)",
    icon: AiOutlineUnorderedList,
  },
  {
    value: "offers",
    label: "Offers",
    endpoint: "(allow only Offers requests in this Collection)",
    icon: AiOutlineDollar,
  },
  {
    value: "stock-estimation",
    label: "Stock Estimation",
    endpoint: "(allow only Stock Estimation requests in this Collection)",
    icon: AiOutlineStock,
  },
  {
    value: "sales-estimation",
    label: "Sales Estimation",
    endpoint: "(allow only Sales Estimation requests in this Collection)",
    icon: AiOutlineFund,
  },
  {
    value: "reviews",
    label: "Reviews",
    endpoint: "(allow only Reviews requests in this Collection)",
    icon: AiOutlineMessage,
  },
  {
    value: "review-comments",
    label: "Review Comments",
    endpoint: "(allow only Review Comments requests in this Collection)",
    icon: AiOutlineSmile,
  },
];

export const hoursOptions = [
  { label: "00.00", value: "00.00" },
  { label: "01.00", value: "01.00" },
  { label: "02.00", value: "02.00" },
  { label: "03.00", value: "03.00" },
  { label: "04.00", value: "04.00" },
  { label: "05.00", value: "05.00" },
  { label: "06.00", value: "06.00" },
  { label: "07.00", value: "07.00" },
  { label: "08.00", value: "08.00" },
  { label: "09.00", value: "09.00" },
  { label: "10.00", value: "10.00" },
  { label: "11.00", value: "11.00" },
  { label: "12.00", value: "12.00" },
  { label: "13.00", value: "13.00" },
  { label: "14.00", value: "14.00" },
  { label: "15.00", value: "15.00" },
  { label: "16.00", value: "16.00" },
  { label: "17.00", value: "17.00" },
  { label: "18.00", value: "18.00" },
  { label: "19.00", value: "19.00" },
  { label: "20.00", value: "20.00" },
  { label: "21.00", value: "21.00" },
  { label: "22.00", value: "22.00" },
  { label: "23.00", value: "23.00" },
];

export const daysOfMonth = [
  {
    label: "1st",
    value: "1",
  },
  {
    label: "2nd",
    value: "2",
  },
  {
    label: "3rd",
    value: "3",
  },
  {
    label: "4th",
    value: "4",
  },
  {
    label: "5th",
    value: "5",
  },
  {
    label: "6th",
    value: "6",
  },
  {
    label: "7th",
    value: "7",
  },
  {
    label: "8th",
    value: "8",
  },
  {
    label: "9th",
    value: "9",
  },
  {
    label: "10th",
    value: "10",
  },
  {
    label: "11th",
    value: "11",
  },
  {
    label: "12th",
    value: "12",
  },
  {
    label: "13th",
    value: "13",
  },
  {
    label: "14th",
    value: "14",
  },
  {
    label: "15th",
    value: "15",
  },
  {
    label: "16th",
    value: "16",
  },
  {
    label: "17th",
    value: "17",
  },
  {
    label: "18th",
    value: "18",
  },
  {
    label: "19th",
    value: "19",
  },
  {
    label: "20th",
    value: "20",
  },
  {
    label: "21st",
    value: "21",
  },
  {
    label: "22nd",
    value: "22",
  },
  {
    label: "23rd",
    value: "23",
  },
  {
    label: "24th",
    value: "24",
  },
  {
    label: "25th",
    value: "25",
  },
  {
    label: "26th",
    value: "26",
  },
  {
    label: "27th",
    value: "27",
  },
  {
    label: "28th",
    value: "28",
  },
  {
    label: "29th",
    value: "29",
  },
  {
    label: "30th",
    value: "30",
  },
  {
    label: "31st",
    value: "31",
  },
];

export const daysOfWeek = [
  { label: "Sunday", value: "sunday" },
  { label: "Monday", value: "monday" },
  { label: "Tuesday", value: "tuesday" },
  { label: "Wednesday", value: "wednesday" },
  { label: "Thursday", value: "thursday" },
  { label: "Friday", value: "friday" },
  { label: "Saturday", value: "saturday" },
];

export const minutesFrequencyOptions = [
  { label: "Every Minute", value: 1 },
  { label: "Every 2 Minutes", value: 2 },
  { label: "Every 5 Minutes", value: 5 },
  { label: "Every 10 Minutes", value: 10 },
  { label: "Every 15 Minutes", value: 15 },
  { label: "Every 30 Minutes", value: 30 },
  { label: "Every Hour", value: 60 },
];

// export const daysOfMonth = (() => {
//   const days = [];
//   for (let i = 1; i <= 31; i++) {
//     const suffix =
//       i % 10 === 1 && i % 100 !== 11
//         ? "st"
//         : i % 10 === 2 && i % 100 !== 12
//         ? "nd"
//         : i % 10 === 3 && i % 100 !== 13
//         ? "rd"
//         : "th";
//     days.push({ label: `${i}${suffix}`, value: `${i}${suffix}` });
//   }
//   return days;
// })();

// export const hoursOptions = (() => {
//   const options = [];
//   for (let i = 0; i < 24; i++) {
//     const value = String(i).padStart(2, '0') + '.00';
//     options.push({ label: value, value: value });
//   }
//   return options;
// })();

export const POSTCODES_DATA = [
  { domain: "amazon.in", zipCode: "123", status: "Active" },
  { domain: "google.com", zipCode: "456", status: "Active" },
  { domain: "example.com", zipCode: "789", status: "Inactive" },
  { domain: "test.net", zipCode: "101", status: "Active" },
];

export const COLLECTIONS_DATA = [
  {
    name: "Summer Sale Collection",
    recordCount: "150",
    status: "Completed",
    type: "One-Time",
    created: "2023-07-15T10:30:00Z",
    completed: "2023-07-15T12:00:00Z",
  },
  {
    name: "Winter Clearance Job",
    recordCount: "200",
    status: "Processing",
    type: "Recurring",
    created: "2023-09-01T09:00:00Z",
    completed: "",
  },
  {
    name: "Spring Launch Collection",
    recordCount: "300",
    status: "Completed",
    type: "One-Time",
    created: "2023-04-10T14:45:00Z",
    completed: "2023-04-10T16:00:00Z",
  },
  {
    name: "Monthly Promotions Job",
    recordCount: "120",
    status: "Processing",
    type: "Recurring",
    created: "2023-08-15T08:00:00Z",
    completed: "",
  },
];

export const apiOptions = [
  // {
  //   value: "product-data",
  //   label: "Product Data API",
  //   endpoint: "/request",
  //   icon: IoSearch,
  // },
  {
    value: "amazon",
    label: "Amazon API",
    endpoint: "",
    icon: IoCardOutline,
  },
];

export const plansOptions = [
  {
    value: "",
    label:
      "Lite Plan - Free for the first 500 scrapes then they have to upgrade",
  },
  { value: "basic", label: "Basic Plan - $25 USD/month: 10,000 scrapes/month" },
  { value: "plus", label: "Plus Plan - $125 USD/month: 250,000 scrapes/month" },
  {
    value: "prime",
    label: "Prime Plan - $550 USD/month: 1,000,000 scrapes/month",
  },
  {
    value: "ultra",
    label: "Ultra Plan - $1,375 USD/month: 2,500,000 scrapes/month",
  },
  {
    value: "pro",
    label: "Pro Plan - $2,750 USD/month: 5,000,000 scrapes/month",
  },
  {
    value: "elite",
    label: "Elite Plan - $7,500 USD/month: 20,000,000 scrapes/month",
  },
];
