import {
  DOCUMENTATION_LIST,
  LOADER_DOCUMENTATION_LIST,
} from "./documentation.action";

const initialReducer = {
  documentationList: [],
  loaderDocumentationList: false,
};

export const documentationReducer = (state = initialReducer, action) => {
  switch (action.type) {
    case DOCUMENTATION_LIST:
      return { ...state, documentationList: action.value };
    case LOADER_DOCUMENTATION_LIST:
      return { ...state, loaderDocumentationList: action.value };

    default:
      return state;
  }
};
