import { Navigate, Route, Routes } from "react-router-dom";
import { Playground } from "../pages/playground/Playground";
import { Usage } from "../pages/usage/Usage";
import { Profile } from "../pages/profile/Profile";
import { SignIn } from "../pages/authentication/sign-in/SignIn";
import { SignUp } from "../pages/authentication/sign-up/SignUp";
import { PostCodes } from "../pages/postcodes/PostCodes";
import { Collections } from "../pages/collections/Collections";
import { PlanAndPayment } from "../pages/plan-payment/PlanAndPayment";
import { ForgotPassword } from "../pages/authentication/forgot-password/ForgotPassword";
import { ResetPassword } from "../pages/authentication/reset-password/ResetPassword";
import { ComingSoon } from "../pages/coming-soon/ComingSoon";
import UpdateNews from "../pages/update-news/UpdateNews";
import DocumentationList from "../pages/documentation/DocumentationList";
import Documentation from "../pages/documentation/Documentation";
import EditCollection from "../pages/collections/EditCollection";
// import { Destinations } from "../pages/destinations/Destinations";
// import { ErrorLogs } from "../pages/error-logs/ErrorLogs";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/playground" />} />
      <Route path="/playground" element={<Playground />} />
      <Route path="/collections" element={<Collections />} />
      <Route path="/collections/:id/edit" element={<EditCollection />} />
      {/* <Route path="/destinations" element={<Destinations />} /> */}

      <Route path="/postcodes" element={<PostCodes />} />

      <Route path="/account" element={<PlanAndPayment />} />
      <Route path="/usage" element={<Usage />} />
      {/* <Route path="/error-logs" element={<ErrorLogs />} /> */}
      <Route path="/profile" element={<Profile />} />

      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/password/forgot" element={<ForgotPassword />} />
      <Route path="/password/reset" element={<ResetPassword />} />
      <Route path="/update-news" element={<UpdateNews />} />
      <Route path="/documentation-list" element={<DocumentationList />} />
      <Route path="/documentation/:id" element={<Documentation />} />

      <Route path="*" element={<ComingSoon />} />
    </Routes>
  );
};
