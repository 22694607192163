import { useEffect, useState } from "react";
import { Collapse, Container, Navbar, Offcanvas } from "react-bootstrap";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Link, NavLink, useLocation } from "react-router-dom";
import { ProfileDropdown } from "./ProfileDropdown";
import { useHeader } from "../../contexts/HeaderContext";
import {
  AiOutlineExperiment,
  AiOutlineHdd,
  AiOutlineShareAlt,
  AiOutlinePushpin,
  AiOutlineUser,
  AiOutlineCreditCard,
  AiOutlineBarChart,
  AiOutlineContainer,
  AiOutlineBook,
  AiOutlineAlert,
  AiOutlineStar,
} from "react-icons/ai";
// import logo from "../../../public/assets/images/logo.png";
export const MobileSidebar = () => {
  const location = useLocation();
  const { accountOpen, setAccountOpen } = useHeader();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    handleClose();
  }, [location.pathname]);

  return (
    <Navbar expand="md" className="bg-body-tertiary d-md-none">
      <Container fluid>
        <Link to="/" className="navbar-brand">
          <img
            width={200}
            height={"auto"}
            src="/assets/images/logo.png"
            alt="logo"
          />
        </Link>
        <Navbar.Toggle onClick={handleShow} />
      </Container>
      <Offcanvas
        show={show}
        placement="end"
        responsive="md"
        onHide={handleClose}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Scraping Control System</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="nav nav-pills flex-column">
            <li className="nav-item">
              <NavLink
                to="/playground"
                className={({ isActive }) =>
                  `nav-link ${isActive ? `active` : "link-body-emphasis"}`
                }
                aria-current="page"
              >
                <AiOutlineExperiment className="bi pe-none me-2" size={16} />
                API Playground
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/collections"
                className={({ isActive }) =>
                  `nav-link ${isActive ? `active` : "link-body-emphasis"}`
                }
              >
                <AiOutlineHdd className="bi pe-none me-2" size={16} />
                Collections
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/destinations"
                className={({ isActive }) =>
                  `nav-link ${isActive ? `active` : "link-body-emphasis"}`
                }
              >
                <AiOutlineShareAlt className="bi pe-none me-2" size={16} />
                Destinations
              </NavLink>
            </li>
          </ul>
          <hr />
          <ul className="nav nav-pills flex-column">
            <li className="nav-item">
              <NavLink
                to="/postcodes"
                className={({ isActive }) =>
                  `nav-link ${isActive ? `active` : "link-body-emphasis"}`
                }
              >
                <AiOutlinePushpin className="bi pe-none me-2" size={16} />
                Postcodes / Zipcodes
              </NavLink>
            </li>
          </ul>
          <hr />
          <ul className="nav nav-pills flex-column mb-auto">
            <li className="nav-item">
              <div
                onClick={() => setAccountOpen(!accountOpen)}
                className="d-flex align-items-center justify-content-between cursor-pointer"
              >
                <span
                  aria-controls="collapse-list"
                  className="nav-link link-body-emphasis"
                >
                  <AiOutlineUser className="bi pe-none me-2" size={16} />
                  Account
                </span>
                {accountOpen ? <FaChevronUp /> : <FaChevronDown />}
              </div>

              <Collapse in={accountOpen}>
                <ul
                  id="collapse-list"
                  className="nav nav-pills flex-column ps-3"
                >
                  <li className="nav-item">
                    <NavLink
                      to="/plan-payment"
                      className={({ isActive }) =>
                        `nav-link ${isActive ? `active` : "link-body-emphasis"}`
                      }
                    >
                      <AiOutlineCreditCard
                        className="bi pe-none me-2"
                        size={16}
                      />
                      Plan & Payment
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/usage"
                      className={({ isActive }) =>
                        `nav-link ${isActive ? `active` : "link-body-emphasis"}`
                      }
                    >
                      <AiOutlineBarChart
                        className="bi pe-none me-2"
                        size={16}
                      />
                      Usage
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/error-logs"
                      className={({ isActive }) =>
                        `nav-link ${isActive ? `active` : "link-body-emphasis"}`
                      }
                    >
                      <AiOutlineContainer
                        className="bi pe-none me-2"
                        size={16}
                      />
                      Error Logs
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/profile"
                      className={({ isActive }) =>
                        `nav-link ${isActive ? `active` : "link-body-emphasis"}`
                      }
                    >
                      <AiOutlineUser className="bi pe-none me-2" size={16} />
                      Profile
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li className="nav-item">
              <NavLink
                to="/documentation"
                className={({ isActive }) =>
                  `nav-link ${isActive ? `active` : "link-body-emphasis"}`
                }
              >
                <AiOutlineBook className="bi pe-none me-2" size={16} />
                Documentation
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/system-status"
                className={({ isActive }) =>
                  `nav-link ${isActive ? `active` : "link-body-emphasis"}`
                }
              >
                <AiOutlineAlert className="bi pe-none me-2" size={16} />
                System Status
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/update-news"
                className={({ isActive }) =>
                  `nav-link ${isActive ? `active` : "link-body-emphasis"}`
                }
              >
                <AiOutlineStar className="bi pe-none me-2" size={16} />
                Update News
              </NavLink>
            </li>
          </ul>
        </Offcanvas.Body>
        <ProfileDropdown className="p-3" />
      </Offcanvas>
    </Navbar>
  );
};
