import { useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Form, ProgressBar, Row } from "react-bootstrap";
import { AiOutlineCopy } from "react-icons/ai";
import { handleCopy } from "../../common/common";
import { BsInfoCircle } from "react-icons/bs";
import { APIKeyModal } from "./Modal";
import { toast } from "react-toastify";
import { TopUpAccountBalance } from "./TopUpAccountBalance";
import PostRequest from "../../services/PostRequest";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setUserSubscription } from "../../reducer/plan-payment/planPayment.action";

export const PlanAndUsage = () => {
  const apiKeyRef = useRef(null);
  const dispatch = useDispatch();
  const _userSubscription = useSelector(
    (item) => item.planPaymentReducer.userSubscription
  );
  const [showAPIKeyModal, setShowAPIKeyModal] = useState(false);
  const [credit, setCredit] = useState(false);
  const [apiKey, setApiKey] = useState({});

  const [showTopUpAccountBalance, setShowTopUpAccountBalance] = useState(false);

  const loadAccountDetails = async () => {
    try {
      const response = await PostRequest("/users/account-details");
      setApiKey(response);
    } catch (error) {
      console.log("error", error.message);
    }
  };
  const loadUserSubscription = () => {
    try {
      dispatch(setUserSubscription());
    } catch (error) {
      console.log("error", error.message);
    }
  };

  const loadCredits = async () => {
    try {
      const response = await PostRequest("/users/credits/");
      setCredit(response);
    } catch (error) {
      console.log("error", error.message);
    }
  };

  useEffect(() => {
    loadAccountDetails();
    loadUserSubscription();
    loadCredits();
  }, []);

  return (
    <>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>
          Your Plan
        </Form.Label>
        <Col className="font-14" sm={9}>
          <p className="mb-0">
            <strong>{_userSubscription?.plan?.name}</strong> Plan
          </p>
          <p className="mb-0">
            {_userSubscription?.plan?.name} Plan giving{" "}
            {_userSubscription?.plan?.credits}{" "}
            {_userSubscription?.plan?.duration} requests
          </p>
          {/* <Form.Label>Change Plan</Form.Label> */}
          <Alert variant="info">
            <BsInfoCircle className="me-1" />
            Upgrade your plan to lower your cost-per request.
          </Alert>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Col sm={3}>API Key</Col>
        <Col sm={9}>
          <div className="d-flex align-items-center">
            <p
              ref={apiKeyRef}
              className="me-2 mb-0 text-break text-truncate fw-bold"
            >
              {apiKey?.api_key}
            </p>
            <AiOutlineCopy
              onClick={() => handleCopy(apiKeyRef.current.innerText)}
              className="cursor-pointer"
            />
          </div>
          <Button
            variant="link"
            onClick={() => setShowAPIKeyModal(true)}
            className="text-decoration-none text-black p-0 border-0 font-14"
          >
            Change API Key
          </Button>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Col sm={3}>Credits</Col>
        <Col className="font-14" sm={9}>
          <ProgressBar style={{ height: 6 }} />
          <p className="mb-0">
            You've used {credit?.used_credits} of the{" "}
            {credit?.available_credits} credits included in your plan.
          </p>
          <p>
            Credits reset when your next billing cycle start at{" "}
            {moment(_userSubscription?.subscription_end).format(
              "HH:mm on DD/MM/YYYY"
            )}
          </p>
          <Button
            variant="link"
            onClick={() => toast.success("Usage refreshed")}
            className="text-decoration-none text-black p-0 border-0 font-14"
          >
            Refresh credit usage
          </Button>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Col sm={3}>Account Balance</Col>
        <Col className="font-14" sm={9}>
          <p className="mb-0">
            Overage changes are deducted first from your Account Balance.
          </p>
          <Button
            variant="link"
            onClick={() => setShowTopUpAccountBalance(true)}
            className="text-decoration-none text-black p-0 border-0 font-14"
          >
            Top Up Account Balance
          </Button>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Col sm={3}>Overage</Col>
        <Col className="font-14" sm={9}>
          <Form.Check type="switch" label="overage rates $0.06 / request" />
        </Col>
      </Form.Group>
      <APIKeyModal
        show={showAPIKeyModal}
        handleClose={() => setShowAPIKeyModal(false)}
      />
      <TopUpAccountBalance
        show={showTopUpAccountBalance}
        handleClose={() => setShowTopUpAccountBalance(false)}
      />
    </>
  );
};
