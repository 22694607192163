import React, { useEffect, useState } from "react";
import { Default } from "../../components/default/Default";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setDocumentationList } from "../../reducer/documentation/documentation.action";
import { AiOutlineReload } from "react-icons/ai";
import { Table } from "react-bootstrap";
import "./documentation.css";

const Documentation = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const _documentationList = useSelector(
    (state) => state.documentationReducer.documentationList
  );
  const _loaderDocumentationList = useSelector(
    (state) => state.documentationReducer.loaderDocumentationList
  );

  useEffect(() => {
    dispatch(setDocumentationList());
  }, []);

  if (_loaderDocumentationList) {
    return (
      <Default>
        <div className="text-center">
          <AiOutlineReload size={50} className="loader-animation" />
        </div>
      </Default>
    );
  }

  const documentation = _documentationList?.find(
    (doc) => doc?.id.toString() === params.id
  );

  if (!documentation) {
    return (
      <Default>
        <p>Documentation not found.</p>
      </Default>
    );
  }

  return (
    <Default>
      <div className="mt-5">
        <h3>{documentation?.title}</h3>
        <Table responsive className="custom-table mt-2">
          <thead>
            <tr>
              <th className="ps-3">Title</th>
              <th>Sort Description</th>
              <th>Long Description</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ width: "220px" }} className="pt-3 custom-title">
                {documentation?.title}
              </td>
              <td
                style={{ width: "470px" }}
                className="pt-3"
                dangerouslySetInnerHTML={{
                  __html: documentation?.short_description,
                }}
              ></td>
              <td
                style={{ width: "470px" }}
                className="pt-3"
                dangerouslySetInnerHTML={{
                  __html: documentation?.long_description,
                }}
              ></td>
              <td className=" pt-3">
                <div className="custom-status ">
                  {documentation.status.charAt(0).toUpperCase() +
                    documentation.status.slice(1).toLowerCase()}
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </Default>
  );
};

export default Documentation;
