import { NavLink, Link } from "react-router-dom";
import {
  // FaBootstrap,
  FaChevronDown,
  FaChevronUp,
  // FaChevronLeft,
} from "react-icons/fa";
import {
  AiOutlineExperiment,
  AiOutlineHdd,
  AiOutlineShareAlt,
  AiOutlinePushpin,
  AiOutlineUser,
  AiOutlineCreditCard,
  AiOutlineBarChart,
  AiOutlineContainer,
  AiOutlineBook,
  AiOutlineAlert,
  AiOutlineStar,
} from "react-icons/ai";
import { Collapse } from "react-bootstrap";
import { ProfileDropdown } from "./ProfileDropdown";
import { useHeader } from "../../contexts/HeaderContext";

export const Expanded = ({ setIsExpanded }) => {
  const { accountOpen, setAccountOpen } = useHeader();
  return (
    <div
      className="d-none d-md-flex flex-column flex-shrink-0 p-3 bg-body-tertiary overflow-y-auto"
      style={{ width: 280 }}
    >
      <Link
        to="/"
        className="d-flex align-items-center justify-content-center link-body-emphasis text-decoration-none"
      >
        {/* <FaBootstrap className="bi pe-none me-2" size={30} /> */}
        {/* <span className="fs-5">Scraping Control System</span> */}
        <img
          width={"100%"}
          height={"auto"}
          src="/assets/images/logo.png"
          alt="logo"
        />
      </Link>
      {/* <div className="d-flex align-items-center justify-content-between">
        <Link
          to="/"
          className="d-flex align-items-center link-body-emphasis text-decoration-none"
        >
          <FaBootstrap className="bi pe-none me-2" size={30} />
          <span className="fs-6">Scraping Control System</span>
        </Link>
        <FaChevronLeft
          className="cursor-pointer"
          onClick={() => setIsExpanded(false)}
        />
      </div> */}
      <hr />
      <ul className="nav nav-pills flex-column">
        <li className="nav-item">
          <NavLink
            to="/playground"
            className={({ isActive }) =>
              `nav-link ${isActive ? `active` : "link-body-emphasis"}`
            }
            aria-current="page"
          >
            <AiOutlineExperiment className="bi pe-none me-2" size={16} />
            API Playground
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/collections"
            className={({ isActive }) =>
              `nav-link ${isActive ? `active` : "link-body-emphasis"}`
            }
          >
            <AiOutlineHdd className="bi pe-none me-2" size={16} />
            Collections
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/destinations"
            className={({ isActive }) =>
              `nav-link ${isActive ? `active` : "link-body-emphasis"}`
            }
          >
            <AiOutlineShareAlt className="bi pe-none me-2" size={16} />
            Destinations
          </NavLink>
        </li>
      </ul>
      <hr />
      <ul className="nav nav-pills flex-column">
        <li className="nav-item">
          <NavLink
            to="/postcodes"
            className={({ isActive }) =>
              `nav-link ${isActive ? `active` : "link-body-emphasis"}`
            }
          >
            <AiOutlinePushpin className="bi pe-none me-2" size={16} />
            Postcodes / Zipcodes
          </NavLink>
        </li>
      </ul>
      <hr />
      <ul className="nav nav-pills flex-column mb-auto">
        <li className="nav-item">
          <div
            onClick={() => setAccountOpen(!accountOpen)}
            className="d-flex align-items-center justify-content-between cursor-pointer"
          >
            <span
              aria-controls="collapse-list"
              className="nav-link link-body-emphasis"
            >
              <AiOutlineUser className="bi pe-none me-2" size={16} />
              Account
            </span>
            {accountOpen ? <FaChevronUp /> : <FaChevronDown />}
          </div>

          <Collapse in={accountOpen}>
            <ul id="collapse-list" className="nav nav-pills flex-column ps-3">
              <li className="nav-item">
                <NavLink
                  to="/account"
                  className={({ isActive }) =>
                    `nav-link ${isActive ? `active` : "link-body-emphasis"}`
                  }
                >
                  <AiOutlineCreditCard className="bi pe-none me-2" size={16} />
                  Plan & Payment
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/usage"
                  className={({ isActive }) =>
                    `nav-link ${isActive ? `active` : "link-body-emphasis"}`
                  }
                >
                  <AiOutlineBarChart className="bi pe-none me-2" size={16} />
                  Usage
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/error-logs"
                  className={({ isActive }) =>
                    `nav-link ${isActive ? `active` : "link-body-emphasis"}`
                  }
                >
                  <AiOutlineContainer className="bi pe-none me-2" size={16} />
                  Error Logs
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/profile"
                  className={({ isActive }) =>
                    `nav-link ${isActive ? `active` : "link-body-emphasis"}`
                  }
                >
                  <AiOutlineUser className="bi pe-none me-2" size={16} />
                  Profile
                </NavLink>
              </li>
            </ul>
          </Collapse>
        </li>
        <li className="nav-item">
          <NavLink
            to="/documentation-list"
            className={({ isActive }) =>
              `nav-link ${isActive ? `active` : "link-body-emphasis"}`
            }
          >
            <AiOutlineBook className="bi pe-none me-2" size={16} />
            Documentation
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/system-status"
            className={({ isActive }) =>
              `nav-link ${isActive ? `active` : "link-body-emphasis"}`
            }
          >
            <AiOutlineAlert className="bi pe-none me-2" size={16} />
            System Status
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/update-news"
            className={({ isActive }) =>
              `nav-link ${isActive ? `active` : "link-body-emphasis"}`
            }
          >
            <AiOutlineStar className="bi pe-none me-2" size={16} />
            Update News
          </NavLink>
        </li>
      </ul>
      <ProfileDropdown />
    </div>
  );
};
