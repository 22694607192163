import { Button, Form, Modal } from "react-bootstrap";
import { BsInfoCircleFill } from "react-icons/bs";
// import ReCAPTCHA from "react-google-recaptcha";
// import { useRef } from "react";
import { PaypalPayment } from "./PaypalPayment";
import {
  GenerateKey,
  handlePapalPaymentInit,
  handlePaypalPaymentApproved,
  showToaster,
} from "../../common/common";
import { plansOptions } from "../../data/constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  planPayments,
  setUserSubscription,
} from "../../reducer/plan-payment/planPayment.action";
// import { SITE_KEY } from "../../config/Api_url";

export const APIKeyModal = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
        <BsInfoCircleFill />
        <span className="ms-1">
          Your API Key will be changed to a new API Key immediately? Are you
          sure?
        </span>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleClose}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const UpdatePaymentMethod = ({ show, handleClose }) => {
  // const recaptchaRef = useRef();

  const onSubmitWithReCAPTCHA = (e) => {
    e.preventDefault();
    // const recaptchaValue = recaptchaRef.current.getValue();
    // console.log('recaptchaValue', recaptchaValue)
    handleClose();
  };

  const onCreate = (data) => {
    // console.log("data", data);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Payment Method</Modal.Title>
      </Modal.Header>
      <Form onSubmit={onSubmitWithReCAPTCHA}>
        <Modal.Body className="p-3">
          <p className="fw-bold">
            Please enter your new payment details below:
          </p>
          {/* <PaypalPayment
            onApprove={onApprove}
            createOrder={() => handlePapalPaymentInit({})}
          /> */}
          {/* <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={SITE_KEY}
            className="mt-3"
          /> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit">
            OK
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export const ChangePlan = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const _planPayment = useSelector(
    (item) => item.planPaymentReducer.planPayment
  );
  const _userSubscription = useSelector(
    (item) => item.planPaymentReducer.userSubscription
  );

  const [selectedPlan, setSelectedPlan] = useState(false);

  const handlePlanChange = (event) => setSelectedPlan(event.target.value);

  const onApprove = async (data) => {
    try {
      const response = await handlePaypalPaymentApproved({
        ...data,
        plan: selectedPlan,
      });
      handleClose();

      showToaster("Credit updated successfully.");
      dispatch(setUserSubscription());
    } catch (error) {
      console.log(error.message);
    }
  };

  const loadPlanPayments = () => {
    try {
      dispatch(planPayments());
    } catch (error) {}
  };

  useEffect(() => {
    loadPlanPayments();
  }, []);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Change Plan</Modal.Title>
      </Modal.Header>
      <Form onSubmit={() => {}}>
        <Modal.Body className="p-3">
          <Form.Group className="mb-3">
            <Form.Label className="fw-bold">Select Plan</Form.Label>
            <Form.Select
              className="text-truncate"
              value={selectedPlan}
              onChange={handlePlanChange}
            >
              {_planPayment?.map((plan) => (
                <option
                  key={plan.id}
                  value={plan.id}
                  selected={plan.name === "Lite Plan"}
                  disabled={plan.name === "Lite Plan"}
                >
                  {plan.title}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          {/* {selectedPlan && selectedPlan?.name !== "Lite Plan" && (
            <PaypalPayment
              key={GenerateKey(10)}
              onApprove={onApprove}
              createOrder={() => handlePapalPaymentInit({ plan: selectedPlan })}
            />
          )} */}
          {selectedPlan && _userSubscription?.plan?.id != selectedPlan && (
            <PaypalPayment
              key={GenerateKey(10)}
              onApprove={onApprove}
              createOrder={() => handlePapalPaymentInit({ plan: selectedPlan })}
            />
          )}

          {/* <PaypalPayment
            key={GenerateKey(10)}
            onApprove={onApprove}
            createOrder={() => handlePapalPaymentInit({ plan: selectedPlan })}
          /> */}

          {/* <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={SITE_KEY}
            className="mt-3"
          /> */}
        </Modal.Body>
      </Form>
    </Modal>
  );
};
