import { Button, Form, Offcanvas } from "react-bootstrap";
import Select from "react-select";
import { ImageLabel } from "../../common/custom-options";
import { showToaster } from "../../common/common";
import PostRequest from "../../services/PostRequest";
import { useDispatch } from "react-redux";
import { setZipCodeList } from "../../reducer/zipCode/ZipCode.action";
import { CountryOption } from "../../common/custom-options";
import { amazonDomainOptions } from "../../data/constants";
import { useState } from "react";

export const AddPostCodes = ({ show, handleClose }) => {
  const dispatch = useDispatch();

  const [selectedAmazonDomain, setSelectedAmazonDomain] = useState(
    amazonDomainOptions[1]
  );

  const handelCreateZipCode = async (e) => {
    try {
      e.preventDefault();
      const formData = new FormData(e.target);

      var data = {};

      formData.forEach(function (value, key) {
        data[key] = value;
      });
      data.country_code = selectedAmazonDomain?.countryCode;

      // console.log("====================================");
      // console.log("create", data);
      // console.log("====================================");

      const option = {
        showLoader: "add-new-zipCode",
      };

      await PostRequest("/zipcodes/create-zipcode/", data, option);

      showToaster("Add new zipcode successfully.", "success");
      dispatch(setZipCodeList());
      handleClose();
    } catch (error) {
      console.log(error.message);
      showToaster(error.message, "error");
    }
  };

  return (
    <Offcanvas placement="end" show={show} onHide={handleClose}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Add Zipcodes/Postal Codes</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form onSubmit={handelCreateZipCode}>
          <Form.Group className="mb-3">
            <Form.Label className="font-14">
              Which domain do you want to add zip or postal codes for?
            </Form.Label>
            <Select
              name="domain"
              required
              options={amazonDomainOptions}
              defaultValue={selectedAmazonDomain}
              getOptionLabel={(option) => <CountryOption data={option} />}
              onChange={(value) => setSelectedAmazonDomain(value)}
              isSearchable={false}
              menuPlacement="auto"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              placeholder="Zipcode"
              name="zipcode"
              as="textarea"
              required
              rows={1}
              className="mb-2"
            />
            <Form.Label className="font-14">
              Enter the zip or postal codes for {selectedAmazonDomain?.label}{" "}
              below, one per line. Your Hobbyist Plan allows for 1 zipcodes to
              be set up in total and you have <strong>1 remaining</strong>
            </Form.Label>
            {/* <Form.Control as="textarea" rows={3} /> */}
          </Form.Group>
          <div className="text-center">
            <Button type="submit" id="add-new-zipCode">
              Add Postcode
            </Button>
          </div>
        </Form>
      </Offcanvas.Body>
      <div className="p-3">
        <Button
          className="me-1"
          variant="outline-primary"
          onClick={handleClose}
        >
          Cancel
        </Button>
        {/* <Button>OK</Button> */}
      </div>
    </Offcanvas>
  );
};
