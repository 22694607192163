import axios from "axios";
import ReactDOMServer from "react-dom/server";
import { API_URL } from "../config/Api_url";

const PostRequest = async (
  path,
  data = {},
  options = { isMultiMedia: false, showLoader: false, auth: true }
) => {
  const { showLoader = false, isMultiMedia = false, auth = true } = options;

  var oldButtonText = "";
  var targetElement = document.getElementById(showLoader);

  if (showLoader && targetElement) {
    oldButtonText = targetElement.innerHTML;
    targetElement.innerHTML = `${ReactDOMServer.renderToString(
      // <LuLoader2 />
      <div className="d-flex align-items-center">
        <div className="loader-button me-2"></div>
        <span>Please wait...</span>
      </div>
    )}`;
    targetElement.setAttribute("disabled", true);
  }

  try {
    const header = {
      "content-type": isMultiMedia ? "multipart/form-data" : "application/json",
    };

    if (auth) {
      header.Authorization = `Token ${window.localStorage.getItem("TOKEN")}`;
    }

    const response = await axios.post(`${API_URL}${path}`, data, {
      headers: header,
      crossDomain: true,
    });

    const responseData = response.data;
    // console.log('response', response.status)

    if (responseData.code == 200) {
      return responseData.data;
    } else {
      if (typeof responseData.data == "object") {
        throw new Error(JSON.stringify(responseData.data));
      } else {
        throw new Error(responseData.data);
      }
    }
  } catch (error) {
    switch (error.status) {
      case 401:
        window.location.href = '/sign-in'
        break;

      default:
        throw new Error(error.message);
        break;
    }
  } finally {
    if (showLoader && targetElement) {
      targetElement.innerHTML = oldButtonText;
      targetElement.removeAttribute("disabled");
    }
  }
};
export default PostRequest;
