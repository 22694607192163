import React, { useEffect, useState } from "react";
import { Default } from "../../components/default/Default";
import { Button, Col, Row } from "react-bootstrap";
import "./update-news.css";
import PostRequest from "../../services/PostRequest";
import moment from "moment/moment";
import { AiOutlineReload } from "react-icons/ai";
const UpdateNews = () => {
  const updateNews = [
    {
      data: "October 2024",
      text1:
        "Irure aliqua eiusmod deserunt nisi. Aute dolore sint nisi qui do cillum anim irure.",
      text2:
        "Irure aliqua eiusmod deserunt nisi. Aute dolore sint nisi qui do cillum anim irure.",
      text3:
        "Labore sunt sunt incididunt minim esse enim est cupidatat sunt adipisicing quis dolore. Ut deserunt labore commodo magna ut in. Et magna sint id sit anim. Consequat duis esse excepteur mollit eiusmod eu ipsum nostrud exercitation excepteur cupidatat eiusmod culpa culpa. Sunt duis ipsum officia veniam duis quis quis voluptate et nostrud ea anim elit pariatur.Nostrud adipisicing incididunt ipsum amet eu irure consectetur et laboris excepteur dolore. Laborum voluptate duis nisi Lorem nostrud incididunt et velit elit. Laborum exercitation aliqua non sint ex do deserunt enim sit laboris do et nostrud. Occaecat velit ad voluptate deserunt amet consectetur nisi proident est dolore anim incididunt ut. Reprehenderit id dolore ipsum officia Lorem esse ad proident dolore eiusmod. Amet ad adipisicing aute duis do adipisicing laboris non ex occaecat. Cupidatat et dolor excepteur non commodo id consectetur minim dolor velit proident.",
      img: "https://images.pexels.com/photos/3990897/pexels-photo-3990897.jpeg?auto=compress&cs=tinysrgb&w=600",
    },

    {
      data: "October 2024",
      text1:
        "Irure aliqua eiusmod deserunt nisi. Aute dolore sint nisi qui do cillum anim irure.",
      text2:
        "Non ea in aliqua adipisicing sunt ad occaecat est anim nulla officia laborum est mollit. Quis tempor exercitation nisi sint reprehenderit ipsum dolore pariatur pariatur minim ad id do. Voluptate incididunt consectetur anim nisi elit proident consectetur dolor ut nulla velit officia Lorem. Veniam ad proident proident dolor minim ex eu exercitation. Id qui nostrud fugiat adipisicing aute reprehenderit eiusmod officia labore nulla culpa magna ullamco. Nisi id ut esse laborum enim elit aliqua sint duis ex.",
      text3:
        "Labore sunt sunt incididunt minim esse enim est cupidatat sunt adipisicing quis dolore. Ut deserunt labore commodo magna ut in. Et magna sint id sit anim. Consequat duis esse excepteur mollit eiusmod eu ipsum nostrud exercitation exc",
      img: "https://images.pexels.com/photos/4069090/pexels-photo-4069090.jpeg?auto=compress&cs=tinysrgb&w=600",
    },

    {
      data: "October 2024",
      text1:
        "Irure aliqua eiusmod deserunt nisi. Aute dolore sint nisi qui do Aliquip ex fugiat do dolor voluptate fugiat labore deserunt. Officia dolore ut velit elit occaecat et ullamco laboris. Cupidatat ex eiusmod eu minim aliqua non eu id tempor et dolore excepteur non.cillum anim irure.",
      text2: "Non ea in aliqua adipi",
      text3:
        "Labore sunt sunt incididunt minim esse enim est cupidatat sunt adipisicing quis dolore. Ut deserunt labore commodo magna ut in. Et magna sint id sit anim. Consequat duis esse excepteur mollit eiusmod eu ipsum nostrud exercitation exSunt quis cillum dolore sunt ex anim nisi deserunt pariatur tempor ad mollit. Non ex esse est quis laborum cillum velit aliqua commodo cillum commodo. Labore id ut sunt ipsum aliqua irure officia. Est quis consectetur irure ea ut nostrud laboris elit. Nisi aute commodo cillum fugiat irure dolore adipisicing nisi sunt duis culpa adipisicing. Culpa sit nulla exercitation aliquip consectetur nostrud laborum ea consectetur exercitation ipsum veniam ex.c",
      img: "https://images.pexels.com/photos/5095897/pexels-photo-5095897.jpeg?auto=compress&cs=tinysrgb&w=600",
    },

    {
      data: "October 2024",
      text1: "Iruure.",
      text2:
        "Non ea in aliqua adipifjiofs sdhf8uysdfsd sd8fusd8uf90sduf sfsdu9fus90",
      text3: "Labore sunt sun",
      img: "https://images.pexels.com/photos/256450/pexels-photo-256450.jpeg?auto=compress&cs=tinysrgb&w=600",
    },
  ];

  const [news, setnews] = useState([]);
  const [loader, setLoader] = useState(false);
  console.log("news", news.length);

  const getNews = async () => {
    try {
      setLoader(true);
      const response = await PostRequest("/news/get_news/");
      setnews(response);
    } catch (error) {
      console.log("error", error.message);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getNews();
  }, []);

  if (!news || news.length === 0) {
    return (
      <Default>
        <p>News not found.</p>
      </Default>
    );
  }

  const Outline = (type) => {
    if (type === "feature") {
      return "outline-success";
    } else if (type === "bug_fixed") {
      return "outline-danger";
    } else if (type === "update") {
      return "outline-warning";
    } else {
      return "outline-secondary";
    }
  };

  const formatText = (text) => {
    // Replace underscores with spaces and capitalize each word
    return (
      text
        .replace(/_/g, " ")
        .split(" ")
        // .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter of each word
        .map((word) => word.toUpperCase())
        .join(" ")
    );
  };

  return (
    <Default>
      {loader ? (
        <div className="text-center">
          <AiOutlineReload size={50} className={`${"loader-animation"}`} />
        </div>
      ) : (
        <Row className="update-news-bg ">
          <Col md={12} className="update-news-bg">
            <div className="timeline-container">
              {news.map((item) => {
                return (
                  <div className="timeline-event">
                    {/* <h5 className="text-bold">November 2024</h5> */}
                    <h5 className="text-bold">
                      {moment(item.updated_at).format("MMMM YYYY")}
                    </h5>
                    <div className="mt-4 mb-4">
                      <Button
                        variant={Outline(item.type)}
                        className="update-nes-btn"
                      >
                        {/* NEW FEATURE */}

                        {formatText(item.type)}
                      </Button>
                      <span className="text-bold ms-2">{item.title}</span>
                    </div>

                    <p
                      dangerouslySetInnerHTML={{
                        __html: item.description,
                      }}
                    ></p>
                    {/* <p className="mt-4">{item.text3}</p>
                  <img className="update-news-img" src={item.img} alt="" /> */}
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
      )}
    </Default>
  );
};

export default UpdateNews;
