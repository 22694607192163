import PostRequest from "../../services/PostRequest";

export const DOCUMENTATION_LIST = "DOCUMENTATION_LIST";
export const LOADER_DOCUMENTATION_LIST = "LOADER_DOCUMENTATION_LIST";

export const setDocumentationList = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADER_DOCUMENTATION_LIST,
        value: true,
      });

      const response = await PostRequest("/documentations/get_documentation/");

      dispatch({
        type: DOCUMENTATION_LIST,
        value: response,
      });
    } catch (error) {
      console.log(error.message);
    } finally {
      dispatch({
        type: LOADER_DOCUMENTATION_LIST,
        value: false,
      });
    }
  };
};
