import { useState } from "react";
import {
  Button, Col, Form,
  // InputGroup, 
  Row
} from "react-bootstrap";
import { ReactMultiEmail } from "react-multi-email";
import { Invoices } from "./Invoices";
import { BillingAddress } from "./BillingAddress";
import { UpdatePaymentMethod } from "./Modal";

export const PaymentDetails = () => {
  const [emails, setEmails] = useState([]);
  const [showInvoices, setShowInvoices] = useState(false);
  const [showBillingAddress, setShowBillingAddress] = useState(false);
  const [showPaymentMethod, setShowPaymentMethod] = useState(false);

  return (
    <>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>
          Billing Address
        </Form.Label>
        <Col className="font-14" sm={8}>
          <p className="mb-0 fw-bold">Calala group PVT LTD</p>
          {/* <p className="mb-0">Hobbyist Plan giving 500 monthly requests</p> */}
          <Button
            variant="link"
            onClick={() => setShowBillingAddress(true)}
            className="text-decoration-none text-black p-0 border-0 font-14"
          >
            Change
          </Button>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>
          Payment Method
        </Form.Label>
        <Col className="font-14" sm={8}>
          <p className="mb-0 fw-bold">Visa ending in 2052</p>
          <Button
            variant="link"
            onClick={() => setShowPaymentMethod(true)}
            className="text-decoration-none text-black p-0 border-0 font-14"
          >
            Update payment method
          </Button>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Col sm={4}>Next Payment</Col>
        <Col className="font-14" sm={8}>
          20 October 2024
        </Col>
      </Form.Group>
      {/* <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>
          Coupon
        </Form.Label>
        <Col className="font-14" sm={8}>
          <InputGroup>
            <Form.Control placeholder="Enter coupon code" />
            <Button>Apply</Button>
          </InputGroup>
        </Col>
      </Form.Group> */}
      <Form.Group as={Row} className="mb-3">
        <Col sm={4}>Invoices</Col>
        <Col sm={8}>
          <Button
            variant="link"
            onClick={() => setShowInvoices(true)}
            className="text-decoration-none text-black p-0 border-0 font-14"
          >
            View Invoices
          </Button>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>
          Billing Emails
        </Form.Label>
        <Col sm={8}>
          <ReactMultiEmail
            emails={emails}
            onChange={(_emails) => setEmails(_emails)}
            enable={({ emailCnt }) => emailCnt < 3}
            className="font-14 text-truncate"
            getLabel={(email, index, removeEmail) => (
              <div data-tag key={index}>
                <div data-tag-item>{email}</div>
                <span data-tag-handle onClick={() => removeEmail(index)}>
                  x
                </span>
              </div>
            )}
          />
          <p className="mb-0 text-muted font-12">
            Up to 3 billing emails can be added.
          </p>
        </Col>
      </Form.Group>
      <Invoices
        show={showInvoices}
        handleClose={() => setShowInvoices(false)}
      />
      <BillingAddress
        show={showBillingAddress}
        handleClose={() => setShowBillingAddress(false)}
      />
      <UpdatePaymentMethod
        show={showPaymentMethod}
        handleClose={() => setShowPaymentMethod(false)}
      />
    </>
  );
};
